import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import './404.scss';

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="Page Not Found | Capital Partners"
    />
    <section className="error-404-page">
      <div className="wrap">
        <div className="big-error-text">
          <h1>Error</h1>
        </div>
        <div className="error-message">
          <p>The page you were looking for doesn’t exist. You may have mistyped the address or the page may have moved.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
